@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Pacifico',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: linear-gradient(to bottom, #AC92EF, #7ED2E6);
  background-repeat: no-repeat;
  font-family: Roboto, sans-serif;

  color: #000000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, Pacifico, 'Courier New',
    monospace;
}

h1 {
  font-family: 'Pacifico', cursive;
  color: white;
  font-size: 3rem;
}

h6 {
  color: white;
  font-size: 0.8rem;
}





